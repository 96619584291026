import Landing from '../views/auth/Landing';
// import Contact from '../components/landing/Contact';
import Demo from '../views/auth/Demo';
// import About from '../components/landing/About';
import About_us from '../views/auth/About_us';
import Privacy_Policy from '../components/landing/Privacy_Policy';
import CookiePolicy from '../components/landing/CookiePolicy';
import TermsofUse from '../components/landing/TermsofUse';

let routes = [
  {
    path: '/',
    component: Landing,
    layout: 'auth',
  },
  {
    path: '/demo',
    component: Demo,
    layout: 'auth',
  },

  // {
  //   path: '/auth/contact',
  //   component: Contact,
  //   layout: 'auth',
  // },
  {
    path: '/about',
    component: About_us,
    layout: 'auth',
  },
  {
    path: '/privacy',
    component: Privacy_Policy,
    layout: 'auth',
  },
  {
    path: '/terms',
    component: TermsofUse,
    layout: 'auth',
  },
  {
    path: '/cookies',
    component: CookiePolicy,
    layout: 'auth',
  },
  // {
  //   path: '/auth/about',
  //   component: About,
  //   layout: 'auth',
  // },
];
export default routes;
