import React from "react";
import Navbar from "./Navbar";
import topphone from "../../assets/img/chirp-imgs/top-phone.png";
import iosbtn from "../../assets/img/chirp-imgs/Download iOS.svg";
import androidbtn from "../../assets/img/chirp-imgs/Download Android.svg";
import { Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

const Headerlanding = (props) => {
  return (
    <header className="header">
      <Navbar />
      <Row md="2" xs="1" className="f-row">
        <Col sm="6" md="7" className="f-coll m-auto">
          <div className="mt-3 pl-xl-3 pl-0">
            <h1 className="font-family-3 text-site-primary">Chirp</h1>
            <h1 className="font-family-3">Communications</h1>
            <p>
              Connect with friends and family like never before. Create groups
              or communicate one-to-one in five different ways. Messaging, Audio
              Call, Video Call, Push Audio, and Push Video. Chirp is a simple,
              user-friendly app, available on both Android and iOS. Scroll down
              to learn more, and see how chirp can make you smile.
            </p>
            <div className="d-flex mt-2 pl-0">
              <a href="https://apps.apple.com/app/id1612220577" target="_blank" className="header-btn">
                <img src={iosbtn} />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.chirpchat.chirpchat" target="_blank" className="header-btn">
                <img src={androidbtn} />
              </a>
            </div>
          </div>
        </Col>
        <Col sm="6" md="5" className="f-colr">
          <img src={topphone} className="mt-" />
        </Col>
      </Row>
    </header>
  );
};

export default Headerlanding;
