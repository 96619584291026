import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import achirp from "../../assets/img/chirp-imgs/atchirp.png";
import psion from "../../assets/img/chirp-imgs/passion.svg";
import vrchirp from "../../assets/img/chirp-imgs/vrchirp.svg";

class About extends Component {
  render() {
    return (
      <>
        <div className=" about">
          {/* <div className="a-banner">
            <h1>about us</h1>
          </div> */}
          <div className="div-1">
            <Row md="2" xs="1" className="f-row1">
              <Col sm="6" md="5" className="f-coll">
                <img src={achirp} className="mt-" width="100%" />
              </Col>
              <Col sm="6" md="7" className="f-colr m-auto">
                <div className="mt-3 pl-4">
                  <h1>At chirp,</h1>
                  <h5>
                    we created an exciting new communications technology
                    platform that connects users globally using messaging,
                    audio, and video.
                  </h5>
                </div>
              </Col>
            </Row>
          </div>
          <div className="div-2">
            <Row md="2" xs="1" className="f-row2">
              <Col sm="7" md="7" className="f-coll m-auto">
                <div className="mt-3 pl-4">
                  <h1>We have a passion</h1>
                  <h5>
                    for interconnectivity using digital technology for friends
                    and family to communicate, no matter where in the world they
                    are located.
                  </h5>
                </div>
              </Col>
              <Col sm="5" md="5" className="f-colr">
                <img src={psion} className="mt-" width="100%" />
              </Col>
            </Row>
          </div>
          <div className="div-1">
            <Row md="2" xs="1" className="f-row1">
              <Col sm="5" md="5" className="f-coll">
                <img src={vrchirp} className="mt-" width="100%" />
              </Col>
              <Col sm="7" md="7" className="f-colr m-auto">
                <div className="mt-3 pl-4">
                  <h1>We are chirp.</h1>
                  <h5>
                    Keeping the world connected in style.<br/> Reshaping
                    communication every step of the way.
                  </h5>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
export default About;
