import axios from 'axios';
import React, { Component } from 'react';
import {
	Card,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	Spinner,
} from 'reactstrap';
import Footer from '../global/Footer';
import Navbar from '../global/Navbar';
// import Stext from "../../assets/img/desktop/Story.png";

class Contact extends Component {
	state = {
		firstName: '',
		lastName: '',
		email: '',
		subject: '',
		message: '',
		loading: false,
	};

	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	handleSubmit = async e => {
		e.preventDefault();
		let obj = {
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			email: this.state.email,
			subject: this.state.subject,
			message: this.state.message,
		};
		this.setState({ loading: true });
		if (
			this.state.firstName &&
			this.state.lastName &&
			this.state.email &&
			this.state.subject &&
			this.state.message
		) {
			const response = await axios.post(
				'https://us-central1-chirp-6526c.cloudfunctions.net/sendMail',
				obj
			);
			if (response.data) {
				this.setState({
					firstName: '',
					lastName: '',
					email: '',
					subject: '',
					message: '',
					loading: false,
				});
			}
		}
	};
	render() {
		return (
			<div className='Contact'>
				<div className='c-banner'>
					<h1>Chirp us and we will chirp you back.</h1>
				</div>
				<Form onSubmit={e => this.handleSubmit(e)}>
					<div className='names'>
						<FormGroup>
							<Input
								className='shadow w-names mr-auto'
								placeholder='First Name'
								name='firstName'
								type='name'
								value={this.state.firstName}
								required
								onChange={e => this.handleChange(e)}
							/>{' '}
						</FormGroup>
						<FormGroup>
							<Input
								className='shadow w-names ml-auto'
								placeholder='Last Name'
								name='lastName'
								type='name'
								value={this.state.lastName}
								required
								onChange={e => this.handleChange(e)}
							/>{' '}
						</FormGroup>
					</div>
					<FormGroup>
						<Input
							className='shadow'
							type='email'
							name='email'
							placeholder='Email Address'
							required
							value={this.state.email}
							onChange={e => this.handleChange(e)}
						/>
					</FormGroup>
					<FormGroup>
						<Input
							className='shadow'
							placeholder='Subject'
							name='subject'
							type='name'
							required
							value={this.state.subject}
							onChange={e => this.handleChange(e)}
						/>{' '}
					</FormGroup>
					<FormGroup>
						<Input
							className='shadow t-area'
							type='textarea'
							name='message'
							placeholder='Message'
							required
							value={this.state.message}
							onChange={e => this.handleChange(e)}
						/>
					</FormGroup>

					<Button className=' mx-auto text-site-secondary'>
						{this.state.loading ? <Spinner /> : 'Submit'}
					</Button>
				</Form>
			</div>
		);
	}
}
export default Contact;
