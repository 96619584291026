import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import msg from "../../assets/img/chirp-imgs/msgimg.png";
import aud from "../../assets/img/chirp-imgs/audimg.png";
import vdio from "../../assets/img/chirp-imgs/vdio-img.png";
import paud from "../../assets/img/chirp-imgs/Paudimg.png";
import pvdio from "../../assets/img/chirp-imgs/p-vdio-img.png";
import gift from "../../assets/img/chirp-imgs/gift.png";
import secure from "../../assets/img/chirp-imgs/secure.png";
import global from "../../assets/img/chirp-imgs/global.png";

class Home extends Component {
  render() {
    return (
      <>
        <div className=" mt- home">
          <div className="div-1">
            <Row md="2" xs="1" className="f-row1">
              <Col sm="5" md="5" className="f-coll">
                <img src={msg} className="mt-" width="100%" />
              </Col>
              <Col sm="7" md="7" className="f-colr m-auto">
                <div className="mt-3 pl-xl-5 pl-lg-0 ml-xl-4 ml-lg-2">
                  <h1>Messaging</h1>
                  <h5>
                    You and your friends are one of a kind. Your messaging app
                    should be too. Share texts, gifs, images, audio, and video
                    to people around the world using our simple interface
                    featuring unique design, and high quality functionality. Now
                    you can express yourself the right way, outside the
                    restrictions of ordinary tech.
                  </h5>
                </div>
              </Col>
            </Row>
          </div>
          <div className="div-2">
            <Row md="2" xs="1" className="f-row2">
              <Col sm="7" md="7" className="f-coll m-auto">
                <div className="mt-3 pl-xl-5 pl-lg-0 ml-xl-4 ml-lg-2">
                  <h1>Audio Call</h1>
                  <h5>
                    Friends close to home? Easy. Long-distance? No problem. Call
                    the ones you love both locally and internationally for free
                    using chirp audio call. Connect with your people anytime and
                    anywhere. Chirp offers top-tier audio, making sure that you
                    can speak and listen with crystal-clear clarity, from
                    anywhere in the world.{" "}
                  </h5>
                </div>
              </Col>
              <Col sm="5" md="5" className="f-colr">
                <img src={aud} className="mt-" width="100%" />
              </Col>
            </Row>
          </div>
          <div className="div-1">
            <Row md="2" xs="1" className="f-row1">
              <Col sm="5" md="5" className="f-coll">
                <img src={vdio} className="mt-" width="100%" />
              </Col>
              <Col sm="7" md="7" className="f-colr m-auto">
                <div className="mt-3 pl-xl-5 pl-lg-0 ml-xl-4 ml-lg-2">
                  <h1>Video Call</h1>
                  <h5>
                    Share moments with your friends and family on a video call
                    to experience the sharp visuals and crystal-clear audio. You
                    will see your loved ones as if they were right in front of
                    you simply by using our free cutting edge video call
                    feature. The ones who mean the most to you are visible with
                    the simple click of a button.
                  </h5>
                </div>
              </Col>
            </Row>
          </div>
          {/* <div className="bg-circle"> */}
          <div className="div-2" id="p-aud-div">
            <Row md="2" xs="1" className="f-row2" id="p-aud">
              <Col sm="7" md="7" className="f-coll m-auto">
                <div className="mt-3 pl-xl-5 pl-lg-0 ml-xl-4 ml-lg-2">
                  <h1>Push Audio</h1>
                  <h5>
                    Get nostalgic with our walkie-talkie style push audio.
                    Instant, groundbreaking, and another one of our awesome
                    signature features that was designed specifically with you
                    in mind. Fun, engaging, and exciting, push audio provides a
                    new way for you to connect with your friends faster than you
                    can say “over and out”.
                  </h5>
                </div>
              </Col>
              <Col sm="5" md="5" className="f-colr" id="fcolr-pa">
                <img src={paud} className="pa-img" width="70%" />
              </Col>
            </Row>
          </div>
          <div className="div-1" id="p-vdio-div">
            <Row md="2" xs="1" className="f-row1">
              <Col sm="5" md="5" className="f-coll">
                <img src={pvdio} className="mt-" width="100%" />
              </Col>
              <Col sm="7" md="7" className="f-colr m-auto">
                <div className="mt-3 pl-xl-5 pl-lg-0 ml-xl-4 ml-lg-2">
                  <h1>Push Video</h1>
                  <h5>
                    Chirp is the only app to offer walkie-talkie style,
                    push-to-stream video communication. Instant, awesome, and
                    available right at your fingertips, making use of the high
                    quality video functions that we are known for. Utilize this
                    feature and see how easy it is to make instant video
                    communication exciting and personal.
                  </h5>
                </div>
              </Col>
            </Row>
          </div>
          {/* </div> */}
          <div className="inc-com">
            <h1>
              The #1 App For <br />
              Instant Communication
            </h1>
            <Row md="3" xs="1" className="div-com">
              <Col>
                <Card className="shadow">
                  <img src={gift} />
                  <h2>Free</h2>
                  <h4>Chirp is forever free for all users.</h4>
                </Card>
              </Col>
              <Col>
                <Card className="shadow">
                  <img src={secure} />
                  <h2>Secure</h2>
                  <h4>Chirp is end to end encrypted.</h4>
                </Card>
              </Col>
              <Col>
                <Card className="shadow">
                  <img src={global} />
                  <h2>Global</h2>
                  <h4>Chirp works all around the world.</h4>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
export default Home;
