import React from 'react';
import Footer from '../../components/global/Footer';
import Contact from '../../components/landing/Contact';
import Navbar from '../../components/global/Navbar';

function Demo() {
  return (
    <>
      <Navbar />
      <Contact />
      <Footer />
    </>
  );
}

export default Demo;
