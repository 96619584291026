import axios from "axios";
import React, { Component } from "react";
import {
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
  Container,
} from "reactstrap";
import Footer from "../global/Footer";
import Navbar from "../global/Navbar";
// import Stext from "../../assets/img/desktop/Story.png";

class CookiePolicy extends Component {
  render() {
    return (
      <>
        <Navbar />
        <div className="Contact">
          <div className="c-banner">
            <h1>GDPR Cookie Policy</h1>
          </div>
          <Container className="my-5">
            <p className="m-0">
              According to the Data Protection Act (GDPR) and MI Comp. Laws Sec.
              750.539d, in order to make navigation on the site as easy as
              possible, the Data Controller uses portions of code installed in
              the browser, which assist him in providing the service according
              to the purposes described ("cookies"). This site can use the
              following types of cookies:{" "}
            </p>
            <ol>
            <br />
              <li className="h3 text-site-heading">
                Essential technical cookies
                <p className="h6 text-site-para">
                  These Cookies are necessary for the correct functioning of the
                  Site. They allow the navigation of the pages, storage access
                  credentials for faster access to the Site, keeping preferences
                  and credentials active during navigation, and saving
                  individual user sessions. Essential technical cookies allow us
                  to provide the service.
                </p>
              </li>
              <br />
              <li className="h3 text-site-heading">
                Statistical and performance cookies
                <p className="h6 text-site-para">
                  These cookies allow you to know how users use the site to
                  evaluate and improve its functioning and better respond to
                  user needs. We can track which pages are the most and most
                  minor frequented through statistical and performance cookies,
                  the number of visitors, the time spent on the site by the
                  average user, and how they arrive. All information collected
                  by these cookies is anonymous and not linked to the user's
                  data.
                </p>
              </li>
              <br />
              <li className="h3 text-site-heading">
                Third-Party Cookies
                <p className="h6 text-site-para">
                  This type of service allows interaction with additional
                  features on the Site or other components based on external
                  platforms. The information acquired by this Site through the
                  user's interactions with these cookies is in any case subject
                  to the settings relating to the management and protection of
                  personal data of the single platform with which the Site
                  interacts. We only use the technical cookies set up by Google
                  Analytics to date.
                </p>
              </li>
            </ol>
            <br />
            <h3 className="text-site-heading">
              User rights, denial and cancellation of the use of cookies
            </h3>
            <p className="m-0">
              At any time, without the consent of third parties, the User can
              manage preferences relating to Cookies directly within their web
              browser and prevent or limit the use of this technology used by
              second and third parties.
              <br />
              <br />
              Through the exact preferences of the web browser, the User can
              delete cookies already installed in the past, denying access to
              sensitive data. In this sense, the user could receive a limited
              service or even not receive the provision of services at all.
              <br />
              <br />
              In the case of services provided by third parties, the User can
              also exercise his right to oppose the tracking by inquiring
              through the third party's privacy policy, expressed in our privacy
              policy. Independently from the provided information, the Data
              Controller informs the user about the use of Your Online Choices.
              Through this service, it is possible to manage the tracking
              preferences of most advertising tools.
            </p>
          </Container>
        </div>
        <Footer />
      </>
    );
  }
}
export default CookiePolicy;
