import React, { useState } from "react";
import Logo from "../../assets/img/chirp-imgs/Logof.png";
import iosbtn from "../../assets/img/chirp-imgs/Download iOS.svg";
import androidbtn from "../../assets/img/chirp-imgs/Download Android.svg";
import social1 from "../../assets/img/chirp-imgs/facebook.svg";
import social2 from "../../assets/img/chirp-imgs/instagram.svg";
import social3 from "../../assets/img/chirp-imgs/twitter.svg";
import social4 from "../../assets/img/chirp-imgs/Linkedin.svg";
import social5 from "../../assets/img/chirp-imgs/youtube.svg";
import {
  Button,
  Col,
  Collapse,
  Container,
  Nav,
  Navbar as RC_Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <footer className="page-footer font-small blue">
        {/* <RC_Navbar className="navbar-footer" expand="lg"> */}
        <Row xs="1" md="3" className="w-100 m-0 py-2 text-center">
        <Col className="d-flex">
          <NavbarBrand className="navbar-header" href="/">
            <img src={Logo} />
          </NavbarBrand>
          </Col>
          <Col className="footer-nav">
            <a href="https://apps.apple.com/app/id1612220577" target="_blank" className="footer-btn">
              <img src={iosbtn} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.chirpchat.chirpchat" target="_blank" className="footer-btn">
              <img src={androidbtn} />
            </a>
          </Col>
          <Col className="footer-social px-0">
            <Link href="#" className="social-btn">
              <img src={social1} />
            </Link>
            <Link href="#" className="social-btn">
              <img src={social2} />
            </Link>

            <Link href="#" className="social-btn">
              <img src={social3} />
            </Link>
            <Link href="#" className="social-btn">
              <img src={social4} />
            </Link>
            <Link href="#" className="social-btn">
              <img src={social5} />
            </Link>
          </Col>
          </Row>
        {/* </RC_Navbar> */}
        <div className="footer-navitems">
          <Nav className="m-auto font-family-nav" navbar>
            <NavItem>
              <Link
                className="navlink"
                activeClass="navlink-active"
                to="/privacy"
                data-target="top"
              target="_self"
              onClick={(e) => window.scrollTo(0, 0)}

              >
                Privacy Policy
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="navlink"
                activeClass="navlink-active"
                to="/terms"
                onClick={(e) => window.scrollTo(0, 0)}

              >
                Terms of Use
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="navlink"
                activeClass="navlink-active"
                to="/cookies"
                onClick={(e) => window.scrollTo(0, 0)}

              >
                Cookie Policy
                
              </Link>
            </NavItem>
          </Nav>
        </div>
      </footer>
    </>
  );
};

export default Footer;
