import React, { Component } from "react";
import { Container } from "reactstrap";
import Footer from "../global/Footer";
import Navbar from "../global/Navbar";

class Privacy_Policy extends Component {
  render() {
    return (
      <>
        <Navbar />
        <div className="Contact">
          <div className="c-banner">
            <h1>GDPR Privacy Policy</h1>
          </div>
          <Container className="my-5">
            <h2>Introductory Considerations</h2>
            <p className="m-0">
              On 25 May 2018, the new General Data Protection Regulation (GDPR -
              General Data Protection Regulation) came into force. The objective
              of the European Union is to provide further protection to personal
              data, defined as "any information concerning an identified or
              identifiable natural person (so-called Data Subject)". This
              Privacy Policy provides users of this site with clear and detailed
              information on the processing of their data according to the
              General Data Protection Regulation (for potential EU-based users),
              MI Comp. Laws Sec. 750.539d (the specific Michigan privacy
              applicable regulation), and Personal Data Protection Code. In
              particular, by consulting this document, the reader will have the
              possibility to confer the set of data collected, the methods of
              their use, and the rights at his disposal regarding the protection
              of sensitive data.
            </p>
            <br />
            <h2>Data Controller (Alias, Data Controller)</h2>
            <p className="m-0">
              According to EU regulation 2016/679, aka General Data Protection
              Regulation, and MI Comp. Laws Sec. 750.539d (Michigan Privacy
              Law), for the CHIRP site available at the URL
              http://www.chirpchatapp.com, Chirp Chat, INC is the Data
              Controller for the processing of personal data collected on users
              of the site itself. For any information or notification regarding
              privacy, please refer to the company email info@chirpchatapp.com.
            </p>
            <br />

            <h2>Data collected through the website</h2>
            <p className="m-0">
              The Personal Data object of CHIRP's activity is related to the
              following categories: personal data about the user, email address
              including features and email providers in case of newsletter’s
              form subscription, metric data on the devices used (such as model,
              brand, operating system and a unique identifier for sending push
              notifications.)
            </p>
            <br />

            <h2>Data Collected by Third Parties</h2>
            <p className="m-0">
              The Site uses the following third-party components that
              independently collect data about users:
            </p>
            {/* <br /> */}
            <h5 className="text-site-heading">
              - Twilio:{" "}
              <span className="small text-site-para font-italic">
                As a set of third-party APIs. The user can read their Privacy
                Policy here.
              </span>
            </h5>
            <h5 className="text-site-heading">
              - Agora:{" "}
              <span className="small text-site-para font-italic">
                For real-time chat and streaming services. The user can read
                their Privacy Policy here.
              </span>
            </h5>
            <h5 className="text-site-heading">
              - Firebase:{" "}
              <span className="small text-site-para font-italic">
                For app monitoring, engaging, and releasing purposes. The user
                can read their Privacy Policy here.
              </span>
            </h5>
            <h5 className="text-site-heading">
              - Nodemailer:{" "}
              <span className="small text-site-para font-italic">
                For email management purposes. The user can read their Privacy
                Policy here.
              </span>
            </h5>
            <br />

            <h2>Purposes and methods of processing </h2>
            <p className="m-0">
              Chirp will process the Personal Data for the exclusive purpose of
              carrying out the typical business operations. They include
              improving/providing the services, covering the tax purposes
              provided for by the law where the company is based, and for
              marketing/contact purposes. Chirp will operate according to this
              agreement, within limits and according to the procedures set out
              in the Privacy Law.
              <br />
              <br />
              <ul>
                It, therefore, undertakes to:
                <li>
                  a) Treat Personal Data lawfully, adopting all organizational
                  measures proper to minimize the processing of only those
                  Personal Data strictly necessary to execute Contracts or legal
                  obligations correctly;
                </li>
                <li>
                  b) Process Personal Data following the information on the
                  methods of processing Personal Data provided to Data Subjects
                  through the information on the processing of Personal Data;
                </li>
                <li>
                  c) Verify that the Personal Data are accurate, relevant,
                  complete and not exceeding the purposes for which they were
                  processed. It includes the reporting in writing to third
                  parties any needs to modify, update, correct or delete
                  Personal Data and undertake to update them, modify, correct or
                  delete them at their request.
                </li>
              </ul>
              <br />
              The treatment will be carried out using IT and telematic tools.
              Such data will not be communicated or accessible to third parties
              except in the cases provided for by law.{" "}
            </p>
            <br />
            <h2>Transfer of Personal Information </h2>
            <li>
              Regarding the transfer of data to third parties, Chirp undertakes
              to follow and correctly apply the instructions dictated by the
              legislation, refraining from defining any methods or purposes of
              processing other than those imposed by the agreements with its
              customers. In other words, in complete agreement with the current
              legislation on data protection (EU 2016/679, art.13/14), any
              potential transfer to third parties will be requested from time to
              time by the user concerned, who may object and deny your consent
              except in the following cases:
            </li>
            <li>
              Transfer upon request for legal proceedings, legal acts, judicial
              and/or police investigations.
            </li>
            <li>
              Transfer for documents and information required by the police.
            </li>
            <li>
              Transfer to our partner for the correct provision of the service
              offered.
            </li>
            <br />
            <br />
            <h2>Data Retention</h2>
            <p className="m-0">
              Chirp collects customer data to provide its services and retains
              this information for the duration of the contracts or at the
              latest until the end of the year of operations to fulfill tax
              purposes. Furthermore, Chirp retains the data for a period after
              the termination of contracts only and only in relation to
              regulatory, tax, insurance and other requirements in the places it
              operates. Once this information is no longer necessary for the
              provision of services, we take measures to prevent access or use
              of this information for purposes other than security, fraud
              prevention and detection. In any case, we do not take legal
              responsibility for any unwanted access or any hacking activity by
              third parties.
              <br />
              <br />
              The data is stored exclusively in electronic and non-paper
              formats.
            </p>
          </Container>
        </div>
        <Footer />
      </>
    );
  }
}
export default Privacy_Policy;
