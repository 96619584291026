import React, { Component } from "react";
import {
  Container,
} from "reactstrap";
import Footer from "../global/Footer";
import Navbar from "../global/Navbar";

class TermsofUse extends Component {
  render() {
    return (
      <>
        <Navbar />
        <div className="Contact">
          <div className="c-banner">
            <h1>Terms of Use</h1>
          </div>
          <Container className="my-5">
            <h2>Introduction</h2>
            <p className="m-0">
              Welcome to CHIRP, a site that can be found and visited at
              http://www.chirpchatapp.com, owned and managed by CHIRP CHAT, INC.
              Using CHIRP, intended as a website (collectively, the "CHIRP", the
              "Service", the "System").
            </p>
            <br />
            <h2>Acceptance</h2>
            <p className="m-0">
              Using our site and our Service, the User expressly agrees to have
              read, understood, and fully accepted our legal documentation
              consisting of Terms and Conditions, Privacy Policy, and Cookie
              Policy.
              <br />
              <br />
              If the User does not accept the content of one or more clauses
              expressed in our legal documentation, please do not use our
              services.
              <br />
              In order to use the site, the User implicitly declares to have the
              faculty of accepting our legal documentation and to be in age. In
              the case of a minor, the User declares to operate under the
              supervision of an adult.{" "}
            </p>
            <br />

            <h2>Changes to Agreements</h2>
            <p className="m-0">
              Occasionally, we may make changes to the Service and/or our legal
              documentation at our discretion. When we make material changes to
              the legal documentation, we will notify users through the System.
              The continued use of CHIRP after such changes involves an express
              acknowledgement and acceptance of the changes.
            </p>
            <br />

            <h2>License and Copyright Policy</h2>
            <p className="m-0">
              Following the DMCA (alias Digital Millennium Copyright Act) and
              the European Copyright Directive (alias EU 2019/790), CHIRP
              Service and the content provided through it are owned by CHIRP or
              its licensors. We grant users a limited, non-exclusive and
              revocable license for their personal and non-commercial use of the
              Service and to receive the multimedia content made available
              through our system in their country, based on the subscription or
              Trial selected (the "License ").
            </p>
            <p className="m-0">
              CHIRP considers itself the owner of all the codes, graphics or
              computer contents present on the site. The user may not assign,
              transfer or sublicense the rights deriving from this License to
              third parties.
            </p>
            <p className="m-0">
              All CHIRP trademarks, service marks, trade names, logos, domain
              names and all other features of CHIRP brand are the exclusive
              property of CHIRP This License does not grant you any right to use
              CHIRP trademarks, service marks, trade names, logos, domain names,
              or other features of CHIRP mark, whether for commercial or
              non-commercial use.
            </p>
            <p className="m-0">
              The user agrees not to use CHIRP Service (including but not
              limited to its content) in any way that is not expressly provided
              for in these Conditions.
            </p>
            <p className="m-0">
              The third-party software collections included in CHIRP Service are
              licensed according to the rules outlined in these Terms or the
              relevant license terms of such third parties.
            </p>

            <br />

             <h2>Third Party Applications</h2>
            <p className="m-0">
              CHIRP, in order to properly provide its services, may (now or in
              the future) use third party applications, websites and content
              directly provided by third parties ("Third Party Applications").
              The use of these applications is subject to their own conditions
              of use. You acknowledge and agree that CHIRP is not responsible
              for any third-party application's behavior, features, or content.
            </p>
            <br />

            <h2>User Generated Content</h2>
            <p className="m-0">
              CHIRP users may (now or in the future) publish, upload and
              contribute ("post") Content on the Service, including text,
              messages, or reviews ("Content"). The user warrants that the
              Content does not violate the agreements in this document,
              applicable law, intellectual property or the rights of others.
              CHIRP does not monitor, review, or modify the published Content
              but reserves the right to remove or disable access for any reason,
              including, but not limited to, Content that violates these
              Agreements in our sole discretion. CHIRP can take these actions
              without prior notification to the user. The removal or disabling
              of access to the Content in question will be our sole discretion.
              We cannot guarantee the removal or disable of any Content that
              violates these Agreements.
            </p>
            <p className="m-0">
              CHIRP is not responsible for the Content posted and does not
              endorse any opinion contained therein. If you believe that any
              Content violates intellectual property rights, please contact us
              using the e-mail address info@chirpchatapp.com.
            </p>
            <br />

            <h2>Site guidelines</h2>
            <p className="m-0">
              CHIRP respects intellectual property rights and expects users to
              do the same. CHIRP has established some ground rules that users
              must follow when using the Service to ensure that CHIRP remains a
              product that all can use. Please follow these rules and encourage
              other users to do the same.
            </p>
            <ul>The following is not permitted for any reason:
            <li>
              copy, redistribute, reproduce, "extract", record, transfer,
              perform or publicly display, transmit or make available to the
              public any part of the CHIRP Service or the Content, or make any
              use of the CHIRP Service or the Content that is not expressly
              permitted under the Agreements or applicable law, or otherwise
              infringes the intellectual property rights (such as copyright) of
              CHIRP Service, Content or any part thereof;
            </li>
            <li>
              use CHIRP Service to import or copy local files for which you do
              not have the necessary legal rights to do so in this way;
            </li>
            <li>
              transfer copies of cached Content from an Authorized Device to any
              other Device by any means;
            </li>
            <li>
              perform reverse engineering, decompile, disassemble, modify or
              create derivative works based on CHIRP Service, Content or any
              part thereof, except where permitted by applicable law;
            </li>
            <li>
              circumvent any CHIRP material or copyright used by CHIRP, its
              licensors or other third parties in order to protect the Content
              or Service;
            </li>
            <li>
              sell, rent, subcontract or lease any part of CHIRP Service or the
              Content;
            </li>
            <li>
              circumvent any territorial restrictions applied by CHIRP or its
              licensors;
            </li>
            <li>
              remove or modify copyright, registered trademarks or other
              indications of intellectual property contained in or provided
              through the CHIRP Service (including in order to hide or alter any
              indications of ownership or origin of any Content);
            </li>
            <li>
              perform the "crawling" within CHIRP Service, or use other
              automated means (including bots, scrapers and spiders) to collect
              information from CHIRP.
            </li></ul>

            <br />
            <h2>Limitations and Variations of the Service</h2>
            <p className="m-0">
              CHIRP will make reasonable efforts to keep CHIRP Service
              operational. However, some technical difficulties or maintenance
              operations can cause temporary interruptions from time to time.
              Within limits established by the law in force, CHIRP reserves the
              right to modify or interrupt, periodically and at any time, and
              temporarily or permanently, functions of CHIRP Service, with or
              without notice and without any liability to users, except where
              prohibited by law, for any interruptions, changes or terminations
              of CHIRP Service or other related functions or functionality. In
              any case, CHIRP is not responsible for any potential failure to
              view and receive emails due to service malfunctions or during
              maintenance periods.
            </p>

            <br />
            <h2>Term and cancellation</h2>
            <p className="m-0">
              The Agreements will remain in force until terminated by the user
              or by CHIRP However, the user understands and accepts that CHIRP
              may at any time suspend or completely deny access to the services
              to any User without any written notice.
            </p>

            <br />
            <h2>Warranty and Disclaimer</h2>
            <p className="m-0">
              CHIRP TRIES TO PROVIDE THE BEST SERVICE POSSIBLE. STILL, THE USER
              UNDERSTANDS AND AGREES THAT CHIRP SERVICE IS PROVIDED "AS IS" AND
              "AS AVAILABLE" WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OR
              CONDITION OF ANY KIND. THE USER USES CHIRP SERVICE AT YOUR OWN
              RISK. TO THE EXTENT PERMITTED BY APPLICABLE LAW, CHIRP AND ALL
              CONTENT OWNERS MAKE NO WARRANTY AND DISCLAIM ANY WARRANTY OR
              CONDITION OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE. CHIRP AND THE CONTENT OWNERS DO NOT WARRANT IN
              ANY WAY THAT CHIRP SERVICE IS FREE OF MALWARE OR OTHER HARMFUL
              COMPONENTS. FURTHERMORE, CHIRP MAKES NO INSURANCE AND DOES NOT
              WARRANT, ENDORSEMENT OR ASSUME LIABILITY FOR ANY THIRD-PARTY
              APPLICATION (OR RELATED CONTENT), USER CONTENT OR OTHER PRODUCTS
              OR SERVICES PUBLISHED OR OFFERED BY THIRD PARTIES OR THIRD
              PARTIES.{" "}
            </p>

            <br />
            <h2>Limitations</h2>
            <p className="m-0">
              THE USER AGREES THAT, TO THE EXTENT PROVIDED BY THE LAW IN FORCE,
              THE ONLY AND SOLE REMEDY FOR ANY PROBLEMS OR DISSATISFACTIONS WITH
              THE CHIRP SERVICE IS TO FINISH THE USE OF THE SERVICE. EVEN IF
              CHIRP ASSUMES NO LIABILITY FOR THIRD-PARTY APPLICATIONS OR THEIR
              CONTENT, AND EVEN IF THE RELATIONSHIP WITH SUCH THIRD-PARTY
              APPLICATIONS MAY BE GOVERNED BY SEPARATE AGREEMENTS WITH SUCH
              THIRD PARTIES, WITHIN THE LIMITS PROVIDED FOR BY LAW. OFFERED BY
              CHIRP FOR ANY PROBLEMS OR DISSATISFACTIONS WITH THIRD-PARTY
              APPLICATIONS OR THEIR CONTENT CONSISTS OF UNINSTALLING AND / OR
              STOPPING USING SUCH THIRD-PARTY APPLICATIONS.
            </p>
            <p className="m-0">
              TO THE MAXIMUM LIMITS PROVIDED BY LAW, IN NO EVENT SHALL CHIRP BE
              LIABLE FOR (1) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE,
              EXEMPLARY OR CONSEQUENTIAL DAMAGES; (2) ANY LOSS OF USE, DATA,
              BUSINESS OR GOODS (WHETHER DIRECT OR INDIRECT), IN ANY CASE
              ARISING FROM THE USE OR INABILITY TO USE THE CHIRP SERVICE,
              THIRD-PARTY APPLICATIONS OR THE CONTENT OF THIRD-PARTY
              APPLICATIONS, REGARDLESS OF ANY LEGAL THEORY AND THE FACT THAT
              CHIRP HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN
              WHERE A REMEDY DOES NOT OBTAIN THE EXPECTED RESULT; OR (3) ANY
              AGGREGATE LIABILITY FOR ANY CLAIMS RELATING TO CHIRP SERVICE,
              THIRD-PARTY APPLICATIONS OR THIRD PARTY APPLICATION CONTENT OF
              VALUE IN EXCESS OF THE AMOUNTS YOU PAID TO CHIRP DURING THE
              PREVIOUS TWELVE MONTHS IN THESE PERIODS. BY THE LAW IN FORCE.
            </p>

            <br />
            <h2>Severability and waiver</h2>
            <p className="m-0">
              Except as otherwise stated in the Agreements, where any provision
              of the Agreements is held to be invalid or unenforceable for any
              reason or to any level, such invalidity or inapplicability will
              not in any way interfere with or render invalid or unenforceable
              the remaining provisions of the Agreements, and the application of
              this clause will be imposed within the limits established by law.
            </p>
            <p className="m-0">
              Any failure to apply the Agreements or related clauses by CHIRP or
              third-party beneficiaries will not cancel the right of CHIRP or
              the third-party beneficiary to do so.
            </p>
            <br />
            <h2>Indemnification</h2>
            <p className="m-0">
              Within the maximum limits provided for by the law in force, the
              user agrees to indemnify and hold CHIRP harmless from and in
              relation to damages, losses and expenses of any kind (including
              reasonable legal costs and fees) resulting from:
            </p>
            <p className="m-0">1) The violation of this Agreement by the user.</p>
            <p className="m-0">2) Any User Content.</p>
            <p className="m-0">
              3) Any activity in which the user engages or performs through
              CHIRP Service.
            </p>
            <p className="m-0">
              4) The breach of any law or the User's rights of a third party.
            </p>

            <br />
            <h2>Jurisdiction</h2>
            <p className="m-0">
              CHIRP operates in full compliance with the laws in force provided
              for by the US legal systems. In this sense, any legal dispute or
              dispute will be resolved in the exclusive courts of the State of
              Michigan.
            </p>

            <br />
            <h2>Contacts</h2>
            <p className="m-0">
              For any questions about CHIRP Service or the Agreements, users can
              contact us via our email address info@chirpchatapp.com.{" "}
            </p>
          </Container>
        </div>
        <Footer />
      </>
    );
  }
}
export default TermsofUse;
